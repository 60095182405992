<template>
  <section id="attachments">
    <pibot-section-heading>
      <slot>Attachments</slot>
    </pibot-section-heading>

    <v-treeview :open.sync="open"
                :items="assetItems"
                :load-children="fetchChildItems"
                hoverable
                transition
                open-on-click
                dense>
      <!-- Prepend -->
      <template v-slot:prepend="{ item, open }">
        <v-icon v-if="!item.file"
                size="21">
          {{ open ? 'fa-folder-open' : 'fa-folder' }}
        </v-icon>

        <v-icon v-else>
          {{getIcon(item)}}
        </v-icon>
      </template>

      <!-- Label -->
      <template v-slot:label="{ item }">
        <a v-if="item.link"
           :href="item.link"
           target="_blank">
          {{item.name}}
        </a>
        <span v-else>
          {{item.name}}
        </span>
      </template>
    </v-treeview>
  </section>
</template>

<script>
import SectionHeading from '@/components/typography/headings/SectionHeading'
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'pibot-asset-attachments',
  components: {
    'pibot-section-heading': SectionHeading
  },
  data () {
    return {
      open: [1],
      icons: {
        default: '',
        html: 'fa-file-code',
        css: 'fa-file-code',
        js: 'fa-file-code',
        json: 'fa-file-code',
        text: 'fa-file-alt',
        image: 'fa-file-image',
        video: 'fa-file-video',
        audio: 'fa-file-audio',
        pdf: 'fa-file-pdf',
        application: 'fa-laptop-code',
        multipart: 'fa-asterisk',
        vnd: 'fa-file'
      }
    }
  },
  computed: {
    ...mapState({
      items: state => state.assets.attachments
    }),
    assetItems () {
      const { id } = this.$route.params
      const itemsClone = JSON.parse(JSON.stringify(this.items))
      itemsClone[0].children = itemsClone[0].children.filter(c => {
        return id.toLowerCase().includes(c.name.toLowerCase())
      })
      return itemsClone
    },
    socketAddress () {
      return process.env.VUE_APP_SOCKET_ADDRESS
    }
  },
  methods: {
    ...mapMutations({
      setAttachments: 'assets/setAttachments'
    }),
    ...mapActions({
      fetchFolderChildren: 'assets/socket_fetchFolderChildren'
    }),
    getIcon ({ contentType }) {
      if (!contentType) return this.icons.default

      const iconKey = Object.keys(this.icons).find(type => contentType.includes(type))
      if (iconKey) return this.icons[iconKey]

      return this.icons.default
    },
    fetchChildItems (item) {
      this.fetchFolderChildren(item.name)
        .then(groupedFiles => {
          const itemsClone = JSON.parse(JSON.stringify(this.items))
          const itemClone = itemsClone[0].children.find(c => c.id === item.id)

          if (!itemClone) return

          for (const filename in groupedFiles) {
            if (filename.toLowerCase().includes(itemClone.name.toLowerCase())) {
              const file = groupedFiles[filename][0]
              itemClone.children.push({
                id: file._id,
                name: file.filename,
                file: true,
                contentType: file.contentType,
                link: `${this.socketAddress}/file/${file._id}`
              })
            }
          }

          // If there are no children, add a line which says 'Empty folder'
          if (!itemClone.children.length) {
            itemClone.children.push({
              id: -1,
              name: 'Empty folder',
              file: true,
              contentType: null
            })
          } else { // Else remove the 'Empty folder' line
            const emptyItemIndex = itemClone.children.findIndex(c => c.id === -1)
            if (emptyItemIndex > -1) itemClone.children.splice(emptyItemIndex, 1)
          }

          this.setAttachments(itemsClone)
          this.open.push(item.id)
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }
}
</script>
