<template>
  <header class="mb-5">
    <h2 class="text-h6"><slot /></h2>
  </header>
</template>

<script>
export default {
  name: 'pibot-section-heading'
}
</script>
